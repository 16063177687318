import axiosEx from '@/configs/http/external-api-axios'
import { CORS_EVERY_WHERE_URL, STATUS_CODE } from '@/utils/constants'
import axiosIn from '@/configs/http/internal-api-axios'
import { convertToFormUrlencodedData } from '@/utils/object-helpers'
import get from 'lodash-es/get'
import store from '@/store/store'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { MESSAGES } from '@/utils/messages'

const baseUrl = 'api/v1/service/google_workspace'
let maxFailAllowGoggle = 2

async function getAccessToken(formData) {
  const url = process.env.VUE_APP_GOOGLE_OAUTH_URL
  const response = await axiosEx
    .post(`${CORS_EVERY_WHERE_URL}${url}`, convertToFormUrlencodedData(formData))
    .then((res) => {
      maxFailAllowGoggle = 2
      return res
    })
    .catch(() => {
      if (maxFailAllowGoggle-- === 0) {
        maxFailAllowGoggle = 2
        return { data: false }
      }
      return getAccessToken(formData)
    })
  if (!response) {
    return false
  }
  return response.data
}

async function fetchListUser(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}`
  return axiosIn
    .get(url)
    .then(async (res) => {
      return needRefreshToken(res.data, () => fetchListUser(subscribe_apps_id))
    })
    .catch((err) => {
      return err
    })
}

async function addUser(subscribe_apps_id, userIds) {
  const url = `${baseUrl}/${subscribe_apps_id}/add`
  return axiosIn
    .post(url, { user_id: userIds.toString() })
    .then(async (res) => {
      return needRefreshToken(res.data, () => addUser(subscribe_apps_id, userIds))
    })
    .catch((err) => {
      return err
    })
}

async function deleteUser(subscribe_apps_id, userIds) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}/remove`
  return axiosIn
    .delete(url, { data: { user_id: userIds.toString() } })
    .then(async (res) => {
      const errorMessage = get(res.data, 'error', '')
      const statusCode = get(res.data, 'statusCode', '')
      if (errorMessage && statusCode === STATUS_CODE.BAD_REQUEST) {
        return handleError(errorMessage)
      }
      return needRefreshToken(res.data, () => deleteUser(subscribe_apps_id, userIds))
    })
    .catch((err) => {
      return err
    })
}

function getDialogState() {
  /*
  true function can be used
  */
  return {
    ADD: false,
    CREATE: true,
    INVITE: false,
    DELETE: true,
    FETCH: true,
  }
}

async function needRefreshToken(response, callback) {
  const statusCode = get(response, 'statusCode', '')
  if (statusCode !== STATUS_CODE.UNAUTHORIZED) {
    return response
  }
  await store.dispatch('loading/showManualLoading')
  try {
    const savedMetadata = store.getters['saas/savedMetadata']
    const service = store.getters['saas/service']
    const postData = {
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
      refresh_token: savedMetadata.refresh_token,
      grant_type: 'refresh_token',
      redirect_uri: `${process.env.VUE_APP_URL}/authorize`,
    }
    // sonar warning here but it's ok
    const result = await getAccessToken(postData)
    if (!result) {
      await store.dispatch('loading/hideManualLoading')
      return {
        success: false,
        message: MESSAGES.COMMON.MSG15,
      }
    }
    await saasCommonService.addToken(service.id, { ...savedMetadata, ...result })
    await store.dispatch('saas/setServiceMetadata', { ...savedMetadata, ...result })
    await store.dispatch('loading/hideManualLoading')
    return callback()
  } catch (e) {
    await store.dispatch('loading/hideManualLoading')
  }
}

function handleError(errorMessages) {
  const result = {
    success: false,
    message: '',
  }
  if (errorMessages.indexOf('Admin cannot delete self') !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.LK11
  }
  return result
}

export const googleWorkspaceService = {
  getAccessToken,
  getDialogState,
  fetchListUser,
  addUser,
  deleteUser,
}
